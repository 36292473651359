//
// Credit Details.
//

.h-credit-details {
  margin: 40px 0 20px;
  padding: 0;
  border-bottom: 1px solid $c-border;
}

.credit-details-list {
  display: block;
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  max-width: 100%;
}

.credit-details-list__item {
  margin: 0 0 10px;
  padding: 0;
  background: none;
}

.credit-details-list__item h2 {
  text-transform: none;
}

// My Business Details.
.business-wrapper {
  position: relative;
  margin: 0 0 25px;
  max-width: 500px;

  @media (min-width: $screen-md) {
    margin: 0 0 16px;
    max-width: 100%;
  }

  .form-item {
    display: inline-block;
    margin: 0 0 8px;
    padding: 0;

    @media (min-width: $screen-md) {
      margin: 0;
    }

    label {
      display: block;
      padding: 0 30px 0 0;

      @media (min-width: $screen-md) {
        display: none;
        padding: 0;
      }
    }

    .form-textarea,
    .form-text {
      width: 100%;
      max-width: 100%;
    }
  }

  .button {
    vertical-align: top;
  }

  .tooltip-info {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;

    @media (min-width: $screen-md) {
      position: relative;
      margin: 4px 45px;
    }
  }
}

// Order.

.credit-details-list__order-list {
  margin: 0 0 15px 0;
  padding: 0;
  list-style: none;
}

.credit-details-list__order-list__item {
  margin: 0 0 8px;
  padding: 0;
  background: none;

  .js-collapsible-list__content {
    padding-top: 10px;
    padding-bottom: 1px;
  }
}

.credit-details-list__order__header {
  display: block;
  flex-wrap: wrap;
  margin: 0;
  padding: 3px 0;
  font-size: 16px;

  @media (min-width: $screen-md) {
    display: flex;
    flex-wrap: nowrap;
  }
  @media (min-width: 1100px) {
    font-size: 18px;
  }
}

.credit-details-list__order__header__number {
  position: relative;
  font-weight: 500;
  text-align: left;

  @media (min-width: $screen-md) {
    font-weight: 300;
    text-align: right;
  }
}

.credit-details-list__order__header__source {
  input {
    pointer-events: none;
  }
}

.credit-details-list__order__header__mobile-label {
  display: inline-block;
  margin-right: 4px;

  @media (min-width: $screen-md) {
    display: none;
  }

  .tooltip-info {
    margin: 0;
  }
}

// Order Legend.

.credit-details-list__legend {
  display: none;
  flex-wrap: nowrap;
  margin: 0 0 10px;
  padding: 15px 0 0 30px;
  font-size: 16px;
  font-weight: 500;

  @media (min-width: $screen-md) {
    display: flex;
  }
  @media (min-width: 1100px) {
    font-size: 18px;
  }

  > div {
    align-self: flex-start;
  }

  .tooltip-info {
    margin: 0;
    font-family: $f-garamond-pro;
  }
}

.credit-details-list__legend--footer {
  display: block;
  padding-top: 5px;
  font-weight: 300;

  @media (min-width: $screen-md) {
    display: flex;
    font-weight: 500;
  }

  > div {
    align-self: flex-start;
  }

  .credit-details-list__legend__num {
    font-weight: 500;
  }
}

.credit-details-list__legend,
.credit-details-list__order__header {
  > div {
    padding-right: 0;
    width: 100%;

    @media (min-width: $screen-md) {
      padding-right: 15px;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .credit-details-list__legend__num,
  .credit-details-list__order__header__number {
    width: auto;
    min-width: auto;
    word-break: break-all;

    @media (min-width: $screen-md) {
      padding: 0 15px 0 0;
      width: 143px;
      min-width: 143px;
    }
    @media (min-width: 1100px) {
      width: 158px;
      min-width: 158px;
    }
  }

  .credit-details-list__legend__date,
  .credit-details-list__order__header__date {
    width: auto;
    min-width: auto;

    @media (min-width: $screen-md) {
      width: 100%;
    }
    @media (min-width: 1100px) {
      width: 100%;
      min-width: 155px;
    }
  }

  .credit-details-list__legend__subtotal,
  .credit-details-list__order__header__subtotal {

  }

  .credit-details-list__legend__source,
  .credit-details-list__order__header__source {
    width: auto;
    min-width: auto;

    @media (min-width: $screen-md) {
      width: 100%;
      min-width: 100px;
    }
  }

  .credit-details-list__legend__total-tax,
  .credit-details-list__order__header__total-tax {
    width: auto;
    min-width: auto;
    text-align: left;

    @media (min-width: $screen-md) {
      width: 100%;
      text-align: right;
    }
    @media (min-width: $screen-md) {
      width: 130px;
      min-width: 130px;
    }
  }
}

// Order Item.

.credit-details-list__order__item {
  display: block;
  flex-wrap: wrap;
  margin: 0 0 20px;
  padding: 0;

  @media (min-width: $screen-sm) {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 10px;
  }
}

.credit-details-list__order__item__sku {
  position: relative;
  padding: 0;
  width: auto;
  min-width: auto;
  word-break: break-all;
  text-align: left;

  @media (min-width: $screen-sm) {
    padding: 0 15px 0 0;
    text-align: left;
  }
  @media (min-width: $screen-md) {
    width: 143px;
    min-width: 143px;
    text-align: right;
  }
  @media (min-width: 1100px) {
    width: 158px;
    min-width: 158px;
  }
}

.credit-details-list__order__item__desc {
  padding: 0;
  width: 330px;
  font-weight: 500;

  @media (min-width: $screen-sm) {
    padding: 0 15px 0 0;
    width: 330px;
    font-weight: 300;
  }
}

.credit-details-list__order__item__quantity {
  display: inline-block;
  padding: 0;
  width: auto;
  min-width: auto;
  vertical-align: top;

  @media (min-width: $screen-sm) {
    display: block;
    padding: 0 15px 0 0;
    width: 85px;
    min-width: 85px;
  }

  &::after {
    content: ' - ';
    display: inline-block;
    margin: 0 5px;
    vertical-align: top;

    @media (min-width: $screen-sm-ph) {
      display: none;
    }
  }
}

.credit-details-list__order__item__price {
  display: inline-block;
  padding: 0;
  width: auto;
  min-width: auto;
  text-align: left;
  vertical-align: top;

  @media (min-width: $screen-sm) {
    display: block;
    width: 135px;
    min-width: 135px;
  }
}


//
// Credit Transfer Details.
//

.credit-transfer-details-list {
  display: block;
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  max-width: 100%;
  font-size: 16px;

  @media (min-width: 1100px) {
    font-size: 18px;
  }
}

.credit-transfer-details-list__item {
  margin: 0 0 10px;
}

.credit-transfer-details-list__year {

}

.credit-transfer-details-table {
  padding: 0 0 15px;
}

.credit-transfer-details-table__row {
  display: block;
  flex-wrap: initial;
  margin: 0 0 10px;
  padding: 0;

  @media (min-width: $screen-md) {
    display: flex;
    flex-wrap: nowrap;
  }

  > div {
    margin: 0;
    padding: 0;
    width: 100%;

    @media (min-width: $screen-md) {
      padding: 0 15px 0 0;
    }

    &.credit-transfer-details-table__date {
      width: 100%;
      min-width: auto;

      @media (min-width: $screen-md) {
        width: 195px;
        min-width: 195px;
      }
    }
  }
}

.credit-transfer-details-table__body {
  margin: 0 0 20px;

  @media (min-width: $screen-md) {
    margin: 0 0 10px;
  }
}

.credit-transfer-details-table__legend {
  font-weight: 500;
}

.credit-transfer-details-table__legend--header {
  display: none;

  @media (min-width: $screen-md) {
    display: flex;
  }

  > div {
    align-self: flex-start;
  }
}

.credit-transfer-details-table__legend--footer {
  font-weight: 300;

  @media (min-width: $screen-md) {
    font-weight: 500;
  }

  .credit-transfer-details-table__date {
    font-weight: 500;
  }
}

.credit-transfer-details-table__mobile-label {
  display: inline-block;
  margin-right: 4px;

  @media (min-width: $screen-md) {
    display: none;
  }
}
