//
// Variables.
//

// Colors.
$c-white: #fff;
$c-black: #000;

$c-brand-green: #8b9931;
$c-brand-green-hover: darken($c-brand-green, 10%);
$c-brand-green-dark: #70ad47;
$c-brand-gray: #58595b;

$c-border: #ccc;
$c-tooltip: #ccc;

// Fonts.
$f-garamond-pro: "adobe-garamond-pro", Garamond, Baskerville, Georgia, Times, serif;

//
// Responsive Helpers.
//

$screen-min: 320px;

// Extra small screen / phone.
$screen-xs: 480px;

// Small screen / large phone.
$screen-sm-ph: 600px;

// Small screen / tablet.
$screen-sm: 768px;

// Medium screen / desktop.
$screen-md: 992px;

// Large screen / wide desktop.
$screen-lg: 1200px;
