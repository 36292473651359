//
// Spa Payment.
//

.spa-payment-details {
  margin: 0 0 20px 12px;
  padding: 0;
}

.spa-payment-details__item {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 0 10px;
  padding: 0;

  > div {
    padding: 0 20px 0 0;

    &:last-child {
      padding-right: 0;
    }
  }
}

.spa-payment-details__item--header {
  font-weight: 500;
}

.spa-payment-details__item__date {
  width: 160px;
  min-width: 160px;
}

.spa-payment-details__item__amount {
  width: 100%;
}
