//
// Collapsible List.
//

.js-collapsible-list {
  position: relative;
  display: block;

  > li {
    clear: both;
    position: relative;
    display: block;
    padding-left: 30px;
    padding-bottom: 0;
  }
}

.js-collapsible-list__expand {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 26px;
  text-align: center;
  font-family: Arial, Verdana, sans-serif;
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  color: $c-brand-green;

  &:hover,
  &:focus {
    color: $c-brand-green-hover;
  }

  &::before {
    content: "+";
    position: relative;
    display: inline;
  }
}

.js-collapsible-list__expand.is-expanded::before {
  content: '-';
  top: -2px;
}

.js-collapsible-list__content {
  display: none;

  &.is-expanded {
    display: block;
  }
}
